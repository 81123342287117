/* eslint-disable import/first */
import React from "react"
import { graphql, Link } from "gatsby"
import { LocaleContext } from "../components/layout"
import SEO from "../components/seo"

var Carousel = require("react-responsive-carousel").Carousel
function _onChange() {}

function _onClickItem() {}

const IndexPage = ({ data: { prismicHomepage } }) => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }

  return (
    <>
      <SEO title="Home" keywords={[`navigo`, `garmin`]} />
      <Carousel
        showArrows={false}
        showThumbs={false}
        infiniteLoop={true}
        infiniteLoop
        autoPlay
        emulateTouch
        onClickItem={_onClickItem}
        onChange={_onChange}
      >
        {prismicHomepage &&
          prismicHomepage.data.image_gallery.map(item => (
            <div className="hero-img">
              <img src={item.hero_image.url} />
            </div>
          ))}
      </Carousel>
{prismicHomepage && prismicHomepage.data.featured_products.length > 0 &&
      <h2
        style={{
          margin: `40px auto`,
          textAlign: `center`,
        }}
      >
        {i18n.featured_products}
      </h2>
}
      <div className="container">
        <div className="row text-center">
          {prismicHomepage && prismicHomepage.data.featured_products.length > 0 &&
            prismicHomepage.data.featured_products.map(item => (
              <div className="col-xs-12 col-sm-6 col-md-4">
                {item.details &&
                  item.details.document.map(product => (
                    <div className="product-box">
                      <div className="product-image">
                        <img
                          className="img-responsive"
                          src={product.data.thumbnail_image.url}
                        />
                      </div>
                      <div className="product-name">
                        {product.data.product_name}
                      </div>
                      <div className="product-price m-b-10 m-t-10">
                          {product.data.product_price}
                        </div>
                      <a href={product.uid}>
                        <div className="product-details">{i18n.details}</div>
                      </a>
                    </div>
                  ))}
              </div>
            ))}
        </div>

        {/* {(prismicHomepage && prismicHomepage.data.special_offer.length > 0) && 
          <h2
          style={{
            margin: `40px auto`,
            textAlign: `center`,
          }}
        >
          {i18n.special_offer}
        </h2>
        } */}
        <div className="row text-center m-t-20">
            {(prismicHomepage && prismicHomepage.data.special_offer.length > 0) &&
              prismicHomepage.data.special_offer.map(item => (
                <div className="col-xs-12 col-sm-6 col-md-4">
                  {item.details &&
                    item.details.document.map(product => (      
                      product.data.product_name !== 'posebna ponuda' &&   
                      <div className="product-box">
                        
                        <div className="product-image">
                        <div class="ribbon ribbon-top-right"><span>{i18n.special_offer}</span></div>
                          <img
                            src={product.data.thumbnail_image.url}
                            className="img-responsive"
                          />
                        </div>
                        <div className="product-name">
                          {product.data.product_name}
                        </div>
                        <div className="product-price m-b-10 m-t-10">
                          {product.data.product_price}
                        </div>
                        <a href={product.uid}>
                          <div className="product-details">{i18n.details}</div>
                        </a>
                      </div>
                    
                    ))}
                </div>
              ))}
        </div>
      </div>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery($locale: String!) {
    prismicHomepage(lang: { eq: $locale }) {
      data {
        header_title {
          text
        }
        featured_products {
          details {
            document {
              uid
              data {
                product_name
                product_price
                thumbnail_image {
                  url
                }
              }
            }
          }
        }
        special_offer {
          details {
            document {
              uid
              data {
                product_name
                product_price
                thumbnail_image {
                  url
                }
              }
            }
          }
        }
        image_gallery {
          hero_image {
            url
          }
        }
      }
    }
  }
`
